import { navigate } from "@reach/router";
import Button from "app/components/ui/button";
import { useStore } from "app/contexts/store-context";
import useConfirmDownload from "app/hooks/use-download";
import { ArrowLeftIcon, ArrowRightIcon, ArtIcon, DotsVerticalIcon, EyeIcon, GridIcon, SaveIcon } from "app/icons";
import clsx from "clsx";
// eslint-disable-next-line import/no-extraneous-dependencies
import { UnorderedListOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import ResumeOverview from "app/main/resumes/overview copy";
import { stepsDrawerAtom } from "app/store";
import { previewDrawerAtom, templateDrawerAtom } from "app/store/template";
import { useAtom } from "jotai";
import useSection from "../../../useSection";

const Navigation = (): JSX.Element => {
  const section = useSection();
  const { downloadResume } = useConfirmDownload();
  const [, toggleDrawer] = useAtom(stepsDrawerAtom);
  const [, showTemplateDrawer] = useAtom(templateDrawerAtom);
  const [overviewVisible, showPreviewDrawer] = useAtom(previewDrawerAtom);

  const {
    state: { resume }
  } = useStore();

  const onNext = () => {
    if (section.next) {
      navigate(`${section.next.name}`);
    }
  };

  const onPrev = () => {
    if (section.prev) {
      navigate(`${section.prev.name}`);
    }
  };

  const handleDownload = () => {
    downloadResume(resume);
  };

  const handlePreview = () => {
    showPreviewDrawer(true);
  };

  const handleCloseOverview = () => {
    showPreviewDrawer(false);
  };

  const handleShowDrawer = () => {
    toggleDrawer(true);
  };

  const handleOpenTemplateDrawer = () => {
    showTemplateDrawer(true);
  };

  const handleRelooking = () => {
    navigate(`/editor/rsme/${resume.id}/appearance`);
  };

  const menu = (
    <Menu
      className=" min-w-[180px]"
      items={[
        {
          key: "10Df",
          label: (
            <Button
              onClick={handleRelooking}
              variant="text"
              startIcon={<ArtIcon className="w-5" color="var(--primary-color)" />}
              className="!w-full !justify-start"
            >
              Personnaliser
            </Button>
          )
        },
        {
          key: "1",
          label: (
            <Button
              onClick={handlePreview}
              variant="text"
              startIcon={<EyeIcon className="w-5" color="var(--primary-color)" />}
              className="!w-full !justify-start"
            >
              Aperçu
            </Button>
          )
        },

        {
          key: "2",
          label: (
            <Button
              onClick={handleOpenTemplateDrawer}
              variant="text"
              startIcon={<GridIcon className="w-5" color="var(--primary-color)" />}
              className="!w-full !justify-start"
            >
              Changez de modèle
            </Button>
          )
        },

        {
          key: "3",
          label: (
            <Button
              onClick={handleDownload}
              variant="text"
              startIcon={<SaveIcon className="w-5" color="var(--primary-color)" />}
              className="!w-full !justify-start"
            >
              Télécharger
            </Button>
          )
        }
      ]}
    />
  );

  return (
    <div className="form-navigation bg-slate-50">
      <div className="block lg:hidden">
        <Button
          className="!w-[48px]"
          color="default"
          variant="outlined"
          endIcon={<UnorderedListOutlined color="#fff" />}
          onClick={handleShowDrawer}
        />
      </div>
      <div className="nav">
        {section?.prev && (
          <Button variant="outlined" startIcon={<ArrowLeftIcon color="var(--primary-color)" />} onClick={onPrev}>
            <span className="hidden sm:inline"> Précédent</span>
          </Button>
        )}

        {!section?.next && (
          <Button
            className={clsx({ "flex-2": !section?.next })}
            variant="contained"
            endIcon={<SaveIcon color="#fff" />}
            onClick={handleDownload}
          >
            <span className="hidden sm:inline"> Télécharger le CV</span>
          </Button>
        )}

        {section?.next && (
          <Button variant="contained" endIcon={<ArrowRightIcon color="#fff" />} onClick={onNext}>
            <span className="hidden sm:inline"> Suivant</span>
          </Button>
        )}
      </div>

      <div className="block sm:hidden">
        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
          <Button className="!w-[48px]" variant="outlined" endIcon={<DotsVerticalIcon />} />
        </Dropdown>
      </div>
      <ResumeOverview isVisible={overviewVisible} resume={resume} onClose={handleCloseOverview} />
    </div>
  );
};

export default Navigation;
