import { Button } from "antd";
import { ModalProps } from ".";

import { StyledFooterContent } from "./styles";

const ModalFooter = ({ footerExtra, cancelText, okText, confirmLoading, onCancel, onOk }: ModalProps): JSX.Element => (
  <StyledFooterContent>
    <div className="extra">{footerExtra}</div>
    <div className="alignButtons">
      {onCancel && (
        <Button onClick={onCancel} className="cancel" type="text">
          {cancelText}
        </Button>
      )}

      {onOk && (
        <Button loading={confirmLoading} onClick={onOk} type="primary">
          {okText}
        </Button>
      )}
    </div>
  </StyledFooterContent>
);

export default ModalFooter;
