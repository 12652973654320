import { RouteComponentProps } from "@reach/router";
import MainLayout from "app/components/ui/layout";

function CGV(props: RouteComponentProps) {
  return (
    <MainLayout>
      <div className="flex flex-col h-full">
        <section className="py-5 section-padding-100">
          <h1 className="text-4xl font-bold text-center text-primary">CONDITIONS GENERALES DE VENTE MON CV PRO</h1>

          <div style={{ paddingLeft: 200, paddingRight: 200, paddingTop: 50 }}>
            <div style={{ marginBottom: 8, fontSize: 14 }}>En vigueur à partir du 18/08/2023</div>
            <div>
              <h4 className="py-4 text-xl font-bold text-primary">Préambule</h4>

              <div>
                <p>
                  Les présentes conditions générales sont conclues pour l’ensemble des produits et services dérivés du
                  site www.moncvpro.com
                </p>
                <p>Entre </p>

                <p>
                  L’entreprise Studies Holding basée à Douala- Cameroun Identifée au Registre du Commerce | NIU : M06
                  221 747 4726 E | RC/DLA/2022/B3740. Les coordonnées de contact à privilégier sont le formulaire de
                  contact du site et l’adresse courriel : infos@moncvpro.com
                </p>

                <p>Ci-après « la Société » pour Studies Holding</p>
                <p>D’une part,</p>
                <p>
                  Et la personne physique ou morale procédant à l’achat de Produits ou Services de la société, Ci-après,
                  « le Client »,
                </p>
                <p>D’autre part.</p>

                <p>
                  La Société est éditrice de Produits et de Services à destination de consommateurs ou d’entreprises,
                  commercialisés par l’intermédiaire de son site internet www.moncvpro.com (le « Site Internet »). Le
                  Client peut utiliser les Services de la Société après avoir effectué le versement des tarifs indiqués
                  dans le cadre de la création, du traitement, du conseil à la rédaction, de l’exportation et du
                  téléchargement des CV et des lettres de motivation (ci-après « Produit » et « Services »).
                </p>

                <h4 className="py-4 text-xl font-bold text-primary">Clause n° 1 : Objet</h4>

                <p>1.1 But des présentes conditions générales de vente</p>
                <p>
                  Les présentes conditions générales de vente déterminent les droits et obligations des parties dans le
                  cadre de la vente en ligne des Produits ou Services proposés par la Société.
                </p>
                <p>1.2 Définitions</p>
                <p>
                  (1) La Société, par l’intermédiaire du Site Internet, propose la vente en ligne des Services et
                  Produits à destination de Clients consommateurs contre un paiement immédiat.
                </p>
                <p>
                  (2) La Société, par l’intermédiaire du Site Internet propose à ses Clients des services de vente en
                  ligne en paiement immédiat, permettant de bénéficier de ses Produits et Services en créant, traitant,
                  exportant et téléchargeant des CV{" "}
                </p>
                <p>
                  (3) Les Services proposés par le Site Internet permettent de vendre en ligne un outil complet de
                  traitement, d’exportation et de téléchargement des CV et lettres de motivation à l’égard des Clients.
                </p>
                <p>
                  (4) Les Produits et Services proposés sur le Site Internet permettent aux Clients d’obtenir des CV *
                  Après avoir procédé à leur création, leur traitement, leur exportation et leur téléchargement contre
                  un paiement immédiat.
                </p>

                <p>
                  (5) Les Clients sont les personnes physiques ou morales qui souhaitent bénéficier des Produits et
                  Services proposés en ligne par la Société Studies Holding à travers son Site Internet.
                </p>

                <h4 className="py-4 text-xl font-bold text-primary">Clause n° 2 : Mesures réglementaires</h4>
                <p>2.1 Description des services</p>
                <p>Le site internet propose à ses Utilisateurs et/ou Clients les services suivants :</p>

                <ul>
                  <li>
                    <p>
                      • L’accès à un contenu, des conseils et des services spécialisés relatifs à la création de
                      documents professionnels tels que le CV, la lettre de motivation et/ou le profil électronique,
                      entre autres (ci-après, « Documents » et/ou « Documents professionnels »).
                    </p>
                    <p>
                      • Un service client disponible 24h/24 et 7j/7, pouvant être contacté via les coordonnées de
                      l’entreprise indiquées dans la rubrique service client des présentes Conditions générales.
                    </p>
                  </li>
                </ul>
                <p>
                  Veuillez noter que le Client est l’unique propriétaire et responsable des documents professionnels,
                  données personnelles et/ou informations privées qu’il crée, modifie, ajoute et/ou garde sur le site
                  internet. En ce sens :
                </p>

                <ul>
                  <li>
                    <p>
                      • Le Client assume l’entière responsabilité de garantir la nature légale, véridique, actualisée et
                      complète des données personnelles et informations privées reprises dans les documents
                      professionnels créés et, le cas échéant, partagés avec des tiers.
                    </p>
                  </li>
                  <li>
                    <p>
                      • L’accès à ces informations et/ou documents par des tiers autres que le Client sera de l’entière
                      responsabilité de l’Utilisateur et/ou du Client. À cet égard, la Société, ses partenaires
                      commerciaux et/ou ses prestataires de services n’en seront en aucun cas tenus responsables.
                    </p>
                  </li>
                </ul>

                <p>
                  En outre, veuillez noter que la Société ne vérifie, contrôle ou modifies en aucun cas les documents
                  professionnels créés par l’Utilisateur et/ou le Client afin de s’assurer que les données fournies
                  soient légales, véridiques et actualisées, ni ne crée de profils d’Utilisateur et/ou de Client basés
                  sur les documents que les Utilisateurs et/ou Client ont créé ou sur les offres d’emplois et/ou cours
                  en ligne qu’ils ont consultés.
                </p>
                <p>2.2 Accès aux services et inscription</p>
                <p>
                  Vous pouvez accéder et naviguer sur le site internet en tant qu’Utilisateur ou Client, à condition
                  d’être un adulte conformément aux lois et règlements de votre pays de naissance ou de résidence.
                </p>
                <p>
                  L’Utilisateur peut naviguer et explorer gratuitement le contenu et les documents professionnels
                  offerts par le site internet sans avoir à créer de compte ou à valider un quelconque paiement.
                </p>
                <p>2.3 Services gratuits sans inscription</p>
                <p>
                  Tous les Utilisateurs peuvent accéder à plusieurs services sans avoir à s’inscrire sur le site
                  internet ou à payer de frais initiaux : Naviguer, consulter et même télécharger les informations et
                  documents en libre accès sur le site, tels que nos guides aux nombreux conseils sur la manière de
                  créer un CV efficace.
                </p>
                <p>Services gratuits avec inscription</p>
                <p>
                  L’utilisation des services suivants est conditionnée par la création d’un compte sur le Site Web, sans
                  qu’il soit nécessaire de sélectionner un abonnement :
                </p>
                <ul>
                  <li>
                    <p>
                      • Compléter et modifier le document professionnel choisi (CV). En ce sens, veuillez noter qu’il
                      est possible de sélectionner l’outil qui vous aide à remplir les champs correspondant selon la
                      profession visée lors de la création de votre document professionnel ;
                    </p>
                  </li>
                  <li>
                    <p>• Prévisualiser le document professionnel (CV) complété sans possibilité de téléchargement. </p>
                  </li>
                </ul>

                <p>Services payants avec inscription</p>
                <p>
                  L’Utilisateur/le Client qui souhaite accéder aux services suivants devra créer un compte sur le site
                  puis{" "}
                </p>
                <p>
                  • Editer son document professionnel (CV) à l’aide de l’outil puis le télécharger complété au format
                  PDF.
                </p>
                <p>• Partager le document professionnel, de manière publique ou privée.</p>
                <p>
                  Pour de plus amples informations, consultez la rubrique « Prix et paiement » de ces Conditions
                  générales.
                </p>
                <p>
                  Dans l’éventualité où vous nous fournissez les données et/ou informations personnelles d’un tiers,
                  qu’il s’agisse d’un adulte ou d’un mineur, vous nous garantissez que vous avez informé le sujet des
                  données des fins du traitement de ses données personnelles et que vous avez obtenu son consentement
                  explicite pour nous communiquer ses données personnelles ou l’autorisation de ses parents ou de son
                  tuteur légal pour nous communiquer ses données personnelles, à moins que le sujet des données ne soit
                  un mineur et que vous êtes son parent ou tuteur légal en raison de la compréhension mutuelle que vous
                  pouvez nous accorder votre consentement pour traiter les données personnelles du mineur en nous les
                  fournissant.
                </p>
                <p>
                  Pour de plus amples informations concernant la manière dont nous traitons les données personnelles,
                  consultez notre politique de confidentialité.{" "}
                </p>

                <h4 className="py-4 text-xl font-bold text-primary">Clause n° 3 : Moyens techniques pour corriger </h4>
                <p>Moyens techniques pour corriger des erreurs</p>
                <p>
                  Si vous détectez qu’une information contenue dans votre document professionnel est incorrecte,
                  incomplète ou imprécise lorsque vous consultez l’aperçu et/ou téléchargez le document, vous serez en
                  mesure d’accéder au document créé depuis votre compte d’utilisateur, de le sélectionner et de corriger
                  les informations nécessaires autant de fois que vous le souhaitez.
                </p>
                <p>
                  Si, pour une raison quelconque, vous ne parvenez pas à effectuer les changements nécessaires sur le
                  document professionnel que vous avez créé, veuillez contacter notre service client par l’un des
                  différents canaux disponibles (e-mail, téléphone).
                </p>

                <h4 className="py-4 text-xl font-bold text-primary">Clause n° 4 : Prix et Paiement. </h4>
                <p>
                  Le prix et le paiement des services offerts par le site internet sont régis par les informations
                  reprises dans cette rubrique. Afin de télécharger le document professionnel créé au format PDF,
                  l’Utilisateur/le Client doit sélectionner la formule de prix adapté à son besoin suivant 02 services
                  offerts.
                </p>

                <p>1-SMART ONE</p>
                <p>
                  • Vous bénéficiez d’un CV professionnel téléchargeable 01 fois et conservé dans votre espace personnel
                  ; le prix du téléchargement à régler avant consultation du document CV au format PDF est de 500 FCFA
                  sauf promotion exceptionnelle. Ce tarif non remboursable est affiché sur le site internet. Dans le
                  cadre du l’offrez SMART ONE, un nouveau paiement sera exigé en cas de nouveau téléchargement que ce
                  soit pour un nouveau CV à éditer ou pour des modifications apportées au CV précédemment téléchargé.
                  Après édition de votre CV via l’outil Moncvpro.com ; si vous souhaitez télécharger votre document au
                  format PDF, une proposition de paiement à valider vous sera affichée afin de vous permettre de
                  sélectionner votre offre -SMART ONE et procéder au paiement suivant les moyens de règlement proposés
                  par l’outil. Une fois le paiement effectué et confirmé, vous pourrez librement télécharger votre CV.
                  Un seul exemplaire vous est dû par le site internet. Un courriel de confirmation de paiement reprenant
                  les détails de votre achat sera envoyé par la société. En ce sens, veuillez noter que : Aucune
                  possibilité de rétractation n’est admise par la société ; de fait l’achat du CV au format PDF est
                  définitif, n’est pas soumis à une période d’évaluation (essai) et ne donne lieu à aucune possibilité
                  de remboursement.
                </p>
                <p>2 -SMART THREE</p>
                <p>
                  • Vous pourrez télécharger jusqu’à 03 versions de votre CV qui seront conservés dans votre espace
                  personnel ; le prix de cette option à régler avant consultation du document CV au format PDF est de
                  1200 FCFA sauf promotion exceptionnelle. Ce tarif non remboursable est affiché sur le site internet.
                  Dans le cadre du l’offrez SMART THREE, vous pouvez télécharger 03 CV à des moments différents en
                  fonction de votre besoin qu’il s’agisse du même document modifié ou de la création d’un nouveau
                  document. Cet achat est valable 06 mois à partir de la date de paiement. Passée la période de 06 mois,
                  vous devrez effectuer un nouveau paiement pour télécharger un ou plusieurs CVs. Après édition de votre
                  CV via l’outil Moncvpro.com ; si vous souhaitez télécharger votre document au format PDF, une
                  proposition de paiement à valider vous sera affichée afin de vous permettre de sélectionner votre
                  offre -SMART THREE et procéder au paiement suivant les moyens de règlement proposés par l’outil. Une
                  fois le paiement effectué et confirmé, vous pourrez librement télécharger votre CV. Trois exemplaires
                  de CV différents récupérables immédiatement ou dans un délai de 06 mois vous sont dus par le site
                  internet. Un courriel de confirmation de paiement reprenant les détails de votre achat sera envoyé par
                  la société. En ce sens, veuillez noter que : Aucune possibilité de rétractation n’est admise par la
                  société ; de fait l’achat du CV au format PDF est définitif, n’est pas soumis à une période
                  d’évaluation (essai) et ne donne lieu à aucune possibilité de remboursement. Les seuls modes de
                  paiement acceptés sont ceux indiqués sur notre site et/ou sur la page de paiement de l’abonnement aux
                  services.
                </p>
                <p>
                  • Les moyens de paiement sont affichés sur le site internet ; les règlements se font uniquement en
                  ligne. En cas de promotion, la valeur à régler par l’utilisateur est présenté soit en validant un
                  coupon de réduction soit suivant les réglages effectués sur le site internet.{" "}
                </p>
                <p>
                  • La Société se réserve le droit de mettre à jour les types et conditions d’achats proposés, en tout
                  temps, conformément à ses objectifs, sa stratégie et ses besoins commerciaux, et ce, sans préavis aux
                  Utilisateurs et/ou Clients.
                </p>
                <p>
                  • La Société se réserve le droit de mettre à jour le prix de la formule indiquée, ainsi que d’ajouter
                  d’autres types de formules et de mettre à jour leurs prix, en tout temps.
                </p>
                <p>
                  • La Société se réserve également le droit de modifier le coût des formules indiquées à tout moment,
                  selon votre pays d’accès au site web et ses objectifs, stratégies et intérêts professionnels.
                </p>
                <p>
                  • Veuillez noter que vous devez être le titulaire légitime du compte de paiement (mobile money)
                  utilisé effectuer le paiement. Par conséquent, la Société ne sera pas tenue responsable si vous n’êtes
                  pas le titulaire ou le titulaire légitime du dit compte. Cette circonstance dépassant notre contrôle
                  raisonnable. Cependant, la Société fournira, le cas échéant, la coopération nécessaire au Client et/ou
                  aux autorités policières et/ou judiciaires dans la détection et/ou l’enquête d’opérations frauduleuses
                  liées au compte utilisé pour effectuer le paiement des services offerts.
                </p>
                <p>
                  • Les paiements mobiles peuvent faire l’objet de contrôles et autorisations par les opérateurs
                  téléphoniques. Si cette entité n’autorise pas le paiement, la Société ne fournira pas les services et
                  ne sera en aucun cas tenue responsable pour absence de prestation des services.
                </p>
                <p>
                  • L’Utilisateur doit s’assurer du bon fonctionnement de son compte de paiement à utiliser pour accéder
                  à nos services. Si votre compte a été bloqué ou n’est pas financièrement solvable, nous ne serons pas
                  en mesure de traiter votre paiement et, par conséquent, nos services ne seront pas fournis. Si vous
                  rencontrez des problèmes de paiement, veuillez contacter votre opérateur mobile.{" "}
                </p>
                <p>
                  • En outre, selon l’origine des transactions de l’Utilisateur, le mode de paiement utilisé et
                  l’origine du mode de paiement, la transaction peut faire l’objet de frais supplémentaires ou de
                  différences de prix liés aux commissions de change. La Société ne sera pas tenue responsable des
                  possibles commissions de change ou frais supplémentaires et n’accordera pas de compensation ou de
                  remboursement des frais supplémentaires imposés par votre banque ou émetteur de carte.
                </p>
                <p>
                  • Bien que la Société ait mis en place les protocoles de sécurité internes appropriés pour faire face
                  à l’utilisation frauduleuse des modes de paiement, elle ne sera en aucun cas responsable de l’absence
                  de mesures de sécurité liées à la possession du compte de paiement, et des clés de sécurité et/ou mots
                  de passe, ces circonstances dépassant notre contrôle raisonnable. Nous n’assumerons pas la
                  responsabilité d’une utilisation frauduleuse mais ferons notre possible pour prévenir une telle
                  situation.
                </p>

                <h4 className="py-4 text-xl font-bold text-primary">
                  Clause n° 5 : Rétractation et dispositions applicables
                </h4>
                <p>En application :</p>
                <p>
                  Les présentes conditions générales de vente régissent les ventes de Produits ou de Services,
                  effectuées au travers du Site Internet ww.moncvpro.com et sont partie intégrante du Contrat entre la
                  Société et le Client. Elles sont pleinement opposables au Client qui les a acceptés expressément avant
                  de passer commande. Des liens vers ces conditions générales de vente ainsi que vers une politique de
                  confidentialité établie par la Société sont précisées sur le site internet et font l’objet d’une
                  obligatoire de validation pour la finalisation de la commande en ligne.{" "}
                </p>
                <p>
                  La Société se réserve la possibilité de modifier les présentes conditions générales de vente, à tout
                  moment, par la publication d’une nouvelle version sur son Site Internet. Les conditions générales de
                  vente applicables alors sont celles étant en vigueur à la date du paiement. Les conditions générales
                  de vente sont consultables sur le Site Internet de la Société à l’adresse suivante : www.moncvpro.com
                  dans l’onglet « Conditions générales » en bas de la page. La Société s’assure que leur acceptation
                  soit claire et sans réserve en mettant en place une case à cocher et un clic de validation. Par
                  l’achat des Produits et Services et le fait de cocher cette case, le Client déclare avoir pris
                  connaissance de l’ensemble des présentes conditions générales et particulières de vente et les
                  accepter sans restriction ni réserve. En effectuant cela, le Client reconnaît également qu’il a
                  bénéficié des conseils et informations nécessaires afin de s’assurer de l’adéquation de l’offre à ses
                  besoins. Le Client déclare être en mesure de contracter légalement en vertu des lois applicables dans
                  son pays de résidence valablement représenter la personne physique ou morale pour laquelle il
                  s’engage. Sauf preuve contraire les informations enregistrées par la Société constituent la preuve de
                  l’ensemble des transactions.
                </p>
                <p>Droit de rétractation :</p>
                <p>
                  Conformément aux lois et règlements applicables, nous informons l’Utilisateur/le Client que, en
                  s’inscrivant et en utilisant les services, il accepte la non-application de son droit de rétraction,
                  car les documents professionnels sont de nature numérique et sont automatiquement consommés par
                  l’Utilisateur/le Client au moment de leur téléchargement.
                </p>

                <h4 className="py-4 text-xl font-bold text-primary">Clause n° 6 : Clause de réserve de propriété</h4>
                <p>
                  La société STUDIES HOLDING conserve la propriété des biens vendus jusqu&apos;au paiement intégral du
                  prix, en principal et en accessoires. À ce titre, si l&apos;acheteur fait l&apos;objet d&apos;un
                  redressement ou d&apos;une liquidation judiciaire, le client se réserve le droit de revendiquer, dans
                  le cadre de la procédure collective, les produits payés.{" "}
                </p>

                <h4 className="py-4 text-xl font-bold text-primary">Clause n° 7 : Mise à disposition des produits</h4>
                <p>La mise à disposition est effectuée :</p>
                <p>
                  • Par l’accès libre au document unique téléchargeable au format PDF dans le cadre de l’offre SMART ONE
                </p>
                <p>• Par l’accès libre aux 03 documents téléchargeables dans le cadre de l’offre SMART THREE</p>
                <p>La mise à disposition des documents édités est immédiate depuis le site internet. </p>

                <h4 className="py-4 text-xl font-bold text-primary">Clause n° 8 : réclamation clientèle</h4>
                <p>
                  Toutes les réclamations sont traitées par le service client joignables par courriel à l’adresse :
                  infos@moncvpro.com. Le formulaire de contact en ligne sur le site internet permet en outre de joindre
                  le service clientèle de la société.{" "}
                </p>

                <h4 className="py-4 text-xl font-bold text-primary">Clause n° 9 : Force majeure</h4>
                <p>
                  La responsabilité de la société ne pourra pas être mise en œuvre si la non-exécution ou le retard dans
                  l&apos;exécution de l&apos;une de ses obligations décrites dans les présentes conditions générales de
                  vente découle d&apos;un cas de force majeure. À ce titre, la force majeure s&apos;entend de tout
                  événement extérieur, panne informatique, panne de courant, rupture d’accès serveur, dysfonctionnements
                  technologiques imprévisibles
                </p>

                <h4 className="py-4 text-xl font-bold text-primary">Clause n° 10 : Tribunal compétent</h4>
                <p>
                  Tout litige relatif à l&apos;interprétation et à l&apos;exécution des présentes conditions générales
                  de vente est soumis au droit Camerounais. À défaut de résolution amiable, le litige sera porté devant
                  le Tribunal de commerce de Douala
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </MainLayout>
  );
}

export default CGV;
