import { useLocation } from "@reach/router";
import { useStore } from "app/contexts/store-context";
import { useEffect, useMemo, useState } from "react";
import { STATIC_STEPS } from "./Editor/resume/steps";

const useSection = (): any => {
  const { pathname } = useLocation();
  const [section, setSection] = useState(null);
  const {
    state: {
      resume: { sections }
    }
  } = useStore();

  const displayedSections = useMemo(() => {
    if (sections?.length) {
      return [...sections, ...STATIC_STEPS];
    }

    return STATIC_STEPS;
  }, [sections]);

  useEffect(() => {
    if (!displayedSections) return;
    const segments = pathname.split("/").filter((segment) => segment?.trim());
    if (segments.length) {
      const path = segments[segments.length - 1];

      const foundStep = displayedSections?.findIndex((item) => item.name === path);

      if (foundStep !== -1) {
        const node: any = {};
        node.current = displayedSections[foundStep];

        if (foundStep > 0) {
          node.prev = displayedSections[foundStep - 1];
        }

        if (foundStep < displayedSections.length) {
          node.next = displayedSections[foundStep + 1];
        }

        setSection(node);
      }
    }
  }, [pathname, displayedSections]);

  return section;
};

export default useSection;
