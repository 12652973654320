import "antd/dist/antd.css";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  :root {
    --primary-color: #735BDB;
    --secondary-color: #17caaf;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-x
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(231, 226, 226);
    border-radius: 30px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 30px;
  }

  body {
    font-family: 'Poppins', sans-serif;
    color: rgba(25, 47, 98, 0.74);
    word-break: break-word;
  }

  .ant-layout {
    background-color: #F6FAFF;
  }

  .app-layout {
    height: 100svh;
    width: 100%;
    background-color: #F6FAFF;
    display: flex;
    flex-direction: column;
  }

  .main {
    overflow-y: auto;
  }

  .ant-input {
    border-radius: 4px;
    height: 48px;

    :hover {
      border-color: var(--primary-color);
    }

    :focus {
      border-color: var(--primary-color);
      box-shadow: none;
    }
  }

  .ant-input-affix-wrapper {
    padding: 0 11px;
    border-radius: 4px;

    input {
      height: 46px;
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--primary-color);
  }

  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: var(--primary-color);
    box-shadow: none;
  }

  .ant-steps {
    .ant-steps-item-finish .ant-steps-item-icon {
      border-color: var(--primary-color);
      background: #F6FAFF;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: var(--primary-color);
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      background-color: var(--primary-color);
    }

    .ant-steps-item-process .ant-steps-item-icon {
      border-color: var(--primary-color);
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: var(--primary-color);
    }
  }

  .ant-btn {
    align-items: center;
    display: flex;
    font-size: 16px;
    justify-content: center;
    gap: 5px;
    border-radius: 4px;

    :hover {
      border-color: #F6FAFF;
      color: var(--primary-color);

      &.ant-btn-primary {
        background-color: #F6FAFF;
        border-color: var(--primary-color);
        color: var(--primary-color);
      }
    }

    &.ant-btn-primary {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: #fff;
    }
  }

  .ant-picker {
    height: 48px;
    width: 100%;
    border-radius: 4px;

    :hover {
      border-color: var(--primary-color);
    }

    :focus {
      border-color: var(--primary-color);
      box-shadow: none;
    }
  }

  .ant-tabs {
    .ant-tabs-ink-bar {
      background-color: var(--primary-color);
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--primary-color);
    }

    .ant-tabs-tab:hover {
      color: var(--primary-color);
    }

    .ant-tabs-content {
      height: 100%;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }

  .ant-drawer {
    .ant-drawer-title {
      color: #000;
      font-size: 20px;
      font-weight: bold;
    }

    .ant-drawer-close {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 16px;

      svg {
        background-color: #edf1f7;
        border-radius: 3px;
        color: #19212c;
        height: 22px;
        padding: 4px;
        width: 22px;
      }
    }

    .ant-drawer-footer {
      padding: 16px 24px;
      border-top: 0;
    }
  }

  .ant-modal-root {
    .ant-modal-confirm-btns {
      display: flex;
      justify-content: end;
    }

    .ant-modal-content {
      border-radius: 8px;
    }
  }

  .ant-alert {
    &.ant-alert-error {
      background-color: #FFE6D9;
      border: 1px solid #FF7D71;
      border-radius: 4px;
      color: white;
    }
  }

  .ant-form {
    .ant-form-item-label > label {
      font-size: 12px;
      color: rgba(25, 47, 98, 0.74);
      font-weight: 500;
    }
    .ant-form-item-explain-error {
      color: #FF4242;
    }
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      border-color: #FF4242;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: none;
    }
  }

  .ant-result {
    .ant-result-title {
      font-size: 18px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .ant-select {
    .ant-select-selector {
      height: 48px!important;
      border-radius: 4px!important;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export default GlobalStyle;
