import { navigate, useLocation } from "@reach/router";
import {
  CertificateIcon,
  EducationIcon,
  HobbyIcon,
  IdentificationIcon,
  LanguagesIcon,
  ProfileIcon,
  ReferenceIcon,
  SkillsIcon,
  StartIcon,
  ToolsIcon,
  TrophyIcon,
  WorkIcon
} from "app/icons";
import { useStore } from "app/contexts/store-context";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { StyledWrapper } from "./styles";

const icons = {
  presentation: <IdentificationIcon />,
  profile: <ProfileIcon />,
  experiences: <WorkIcon />,
  educations: <EducationIcon />,
  skills: <SkillsIcon />,
  languages: <LanguagesIcon />,
  interests: <HobbyIcon />,
  certificates: <CertificateIcon />,
  references: <ReferenceIcon />,
  strengths: <StartIcon />,
  awards: <TrophyIcon />,
  projects: <ToolsIcon />
};

export const STATIC_STEPS = [
  {
    _id: "dfsdws",
    icon: "complete",
    id: "dfsdws",
    isMovable: "",
    isVisible: "",
    label: "Finalisez votre CV",
    name: "review",
    uid: "dfsdws",
    updatedAt: ""
  }
];

interface StepItemProps {
  item: any;
  isCurrent: boolean;
}
export const StepItem = ({ item, isCurrent }: StepItemProps) => {
  const onClick = () => {
    navigate(item.name);
  };

  return (
    <div
      onClick={onClick}
      className={clsx("step-item relative", {
        current: isCurrent
      })}
      role="button"
      aria-hidden
    >
      <div className="icon">{icons[item.name]}</div>
      <div className="info">
        <span>{item.label}</span>
      </div>

      {/* {!item.enable && (
        <span className="absolute right-2 bg-primary text-white rounded-full p-1 text-[9px] top-2 flex items-center justify-between gap-1">
          <LockOutlined />
          BIENTÔT
        </span>
      )} */}
    </div>
  );
};

const EditionSteps = (): JSX.Element => {
  const { pathname } = useLocation();
  const [current, setCurrent] = useState(null);
  const {
    state: {
      resume: { sections }
    }
  } = useStore();

  useEffect(() => {
    const segments = pathname.split("/").filter((segment) => segment?.trim());

    if (segments.length) {
      setCurrent(segments[segments.length - 1]);
    }
  }, [pathname]);

  const displayedSections = useMemo(() => {
    if (sections?.length) {
      return [...sections, ...STATIC_STEPS];
    }

    return STATIC_STEPS;
  }, [sections]);

  return (
    <StyledWrapper row={displayedSections?.length} className="hidden pt-0 lg:block">
      <div className="steps-grid">
        {displayedSections?.map((item) => (
          <StepItem key={item.id} item={item} isCurrent={item.name === current} />
        ))}
      </div>
      {/* <div className="more-options">
        <Button variant="outlined" className="new-section">
          <AddIcon />
          Ajouter une section
        </Button>
      </div> */}
    </StyledWrapper>
  );
};

export default EditionSteps;
