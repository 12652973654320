/* eslint-disable react/no-array-index-key */
import styled from "styled-components";

const StyledColorItem = styled.div`
  background-color: ${(props) => props.color};
  width: 60px;
  height: 30px;
  display: inline-block;
  border: 2px solid #e5e7eb;
`;

interface ColorsListProps {
  type: string;
  colors: string[];
  onClick: (value: { type: string; color: string; index: number }) => void;
}

function ColorsList({ type, colors, onClick }: ColorsListProps) {
  const handleClick = (e) => {
    e.stopPropagation();
    const {
      dataset: { index = null, color = null }
    } = e.currentTarget;

    if (index && color) {
      onClick({ type, color, index: Number(index) });
    }
  };
  return (
    <div className="flex gap-2">
      {colors.map((color, index) => (
        <StyledColorItem
          key={`${type}-${index}`}
          data-index={index}
          data-color={color}
          aria-hidden
          color={color}
          onClick={handleClick}
        />
      ))}
    </div>
  );
}

export default ColorsList;
