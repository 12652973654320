import { ApolloError, useMutation } from "@apollo/client";
import { navigate, RouteComponentProps } from "@reach/router";
import { Modal, notification, Result } from "antd";
import Button from "app/components/ui/button";
import HTTP_STATUS_CODES from "app/constants/http-status-codes";
import { REGISTER } from "app/graphql/auth/mutations";
import React, { useState } from "react";
import SignupForm from "../components/signup-form";
import { StyledFormWrapper } from "../styles";
import { ILoginFormValues } from "../types";

const Signup: React.FC<RouteComponentProps> = (): JSX.Element => {
  const [register, { loading }] = useMutation(REGISTER);
  const [isSuccess, setSuccess] = useState(false);

  const handleSubmit = (formValues: ILoginFormValues) => {
    register({
      variables: {
        input: {
          ...formValues
        }
      }
    })
      .then(() => {
        setSuccess(true);
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors?.forEach((err) => {
          if ((err.extensions.response as any).statusCode === HTTP_STATUS_CODES.CONFLICT) {
            notification.error({ message: "Cet e-mail est déjà utilisé!" });
          }
        });
      });
  };

  const handleGotoLogin = () => {
    navigate("/login");
  };

  const handleEmailMessage = () => {
    Modal.warn({
      title: "Vous n'avez pas reçu votre e-mail ?",
      content: (
        <div>
          Il est possible qu&apos;il se trouve dans votre dossier de courriels indésirables ou dans l&apos;onglet
          &quot;Promotions&quot; de Gmail. Veuillez vérifier ces dossiers et ajouter notre adresse à votre liste de
          contacts pour vous assurer de recevoir tous nos courriels à l&apos;avenir.
        </div>
      ),
      closable: true
    });
  };

  return (
    <StyledFormWrapper>
      {isSuccess ? (
        <Result
          style={{ padding: 0 }}
          status="success"
          title="Pour finaliser votre inscription, veuillez cliquer sur le lien de confirmation qui vient de vous être envoyé par e-mail."
          subTitle=""
          extra={[
            <Button key="login" onClick={handleGotoLogin}>
              Page de connexion
            </Button>,
            <Button key="email-not-send" className="mt-5" onClick={handleEmailMessage}>
              Vous n&apos;avez pas reçu votre e-mail ?
            </Button>
          ]}
        />
      ) : (
        <>
          <div className="pb-5 text-3xl font-bold text-center text-primary">Inscription</div>
          <SignupForm submitted={loading} onSubmit={handleSubmit} />
        </>
      )}
    </StyledFormWrapper>
  );
};

export default Signup;
