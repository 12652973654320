import styled from "styled-components";

export const StyledSkillsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
`;

export const StyledSkillItem = styled.div`
  align-items: center;
  background-color: #f6faff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  padding: 0px 8px;
  gap: 5px;
  width: 100%;
  justify-content: space-between;
  border-radius: 6px;

  :hover {
    background-color: #f9fcff;
  }

  svg {
    height: 18px;
    width: 18px;
  }

  .level {
    align-items: center;
    background: var(--primary-color);
    border-radius: 5px;
    box-shadow: 2px 5px 10px -1px rgba(80, 42, 205, 0.37);
    color: #fff;
    display: inline-flex;
    font-size: 14px;
    height: 25px;
    justify-content: center;
    margin-right: 5px;
    width: 25px;
  }

  .close {
    background-color: transparent;
    border: 0;
    padding: 0;
  }
`;

export const StyledSkillInputWrapper = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  flex: 1;
  gap: 5px;
  padding: 8px;

  > div {
    background-color: rgb(217, 217, 217, 0.2);
    display: flex;
    padding: 2px;
    width: 100%;
  }

  .input-wrapper {
    flex: 1;
  }

  .slider-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .ant-input {
    background-color: transparent;
    border: 0;
  }

  button {
    height: 100%;
    svg {
      width: 18px;
      height: 18px;
    }

    .check {
      display: none;
    }

    :hover {
      .plus {
        display: none;
      }

      .check {
        display: block;
      }
    }
  }
`;
