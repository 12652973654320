import { ITemplateTheme } from "app/types";

export const DRAWER_WITH = 240;
export const APP_BAR_HEIGHT = 64;
export const LOGO_PATH = "/logo.png";

export const MONTHS = [
  { label: "Janvier", value: 0 },
  { label: "Février", value: 1 },
  { label: "Mars", value: 2 },
  { label: "Avril", value: 3 },
  { label: "Mai", value: 4 },
  { label: "Juin", value: 5 },
  { label: "Juillet", value: 6 },
  { label: "Août", value: 7 },
  { label: "Septembre", value: 8 },
  { label: "Octobre", value: 9 },
  { label: "Novembre", value: 10 },
  { label: "Décembre", value: 11 }
];

const CURRENT_YEAR = new Date().getFullYear();
export const YEARS_RANGE = Array.from({ length: 50 }, (_, i) => CURRENT_YEAR - i);

export const LanguageLevelLabel = {
  0: "Choisissez votre niveau",
  1: "Débutant",
  2: "Intermédiaire",
  3: "Avancé",
  4: "Courant",
  5: "Bilingue ou Natif"
};

export const LanguageLevelLabelArray = Object.entries(LanguageLevelLabel).map(([value, label]) => ({
  label,
  value: Number(value)
}));

export const SkillLevelLabel = {
  0: "Choisissez votre niveau",
  1: "Débutant",
  2: "Intermédiaire",
  3: "Avancé",
  4: "Expert",
  5: "Maîtrise"
};

export const SkillLevelLabelArray = Object.entries(SkillLevelLabel).map(([value, label]) => ({
  label,
  value: Number(value)
}));

export interface ITemplate {
  path: string;
  component: string;
  theme?: ITemplateTheme;
}
export const TEMPLATES: ITemplate[] = [
  {
    path: "/resumes/resume-01.png",
    component: "01",
    theme: {
      colors: {
        bg: {
          "bg-1": "#2d2f32",
          "bg-2": "#ffffff"
        },
        text: {}
      }
    }
  },
  {
    path: "/resumes/resume-02.png",
    component: "02",
    theme: {
      colors: {
        bg: {
          "bg-1": "#504ea1",
          "bg-2": "#ffffff"
        },
        text: {}
      }
    }
  },
  {
    path: "/resumes/resume-03.png",
    component: "03",
    theme: {
      colors: {
        bg: {
          "bg-1": "#e9be27",
          "bg-2": "#ffffff"
        },
        text: {}
      }
    }
  },
  {
    path: "/resumes/resume-04.png",
    component: "04",
    theme: {
      colors: {
        bg: {
          "bg-1": "#2d2f32",
          "bg-2": "#ffffff"
        },
        text: {}
      }
    }
  },
  {
    path: "/resumes/resume-05.png",
    component: "04",
    theme: {
      colors: {
        bg: {
          "bg-1": "#2d2f32",
          "bg-2": "#ffffff"
        },
        text: {}
      }
    }
  }
];

export const FONT_SIZE_RATIO = {
  XSS: 0.625,
  XS: 0.75,
  S: 0.875,
  M: 1,
  L: 1.125,
  XL: 1.25,
  XLL: 1.375
};

export const INPUT_ROW_SPACING = [12, 12] as any;
