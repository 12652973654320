import { Form, Input } from "antd";
import Button from "app/components/ui/button";
import Loader from "app/components/ui/loader";
import { ISignupFormValues, SignupFormProps } from "../types";

function LoginForm({ submitted, onSubmit: onLogin }: SignupFormProps): JSX.Element {
  const handleLogin = ({ lastname, firstname, password, email, phone }: ISignupFormValues) => {
    onLogin({ lastname, firstname, password, email, phone });
  };

  return (
    <Form name="signup-form" onFinish={handleLogin} layout="vertical">
      <div className="space-y-5">
        <Form.Item label="Nom" name="lastname" rules={[{ required: true, message: "Veuillez saisir votre nom" }]}>
          <Input type="text" placeholder="Votre nom" />
        </Form.Item>

        <Form.Item
          label="Prénom"
          name="firstname"
          rules={[{ required: true, message: "Veuillez saisir votre prénom" }]}
        >
          <Input type="text" placeholder="Votre prénom" />
        </Form.Item>

        <Form.Item
          label="Numéro de téléphone"
          name="phone"
          rules={[{ required: true, message: "Veuillez saisir votre numéro de téléphone" }]}
        >
          <Input type="text" placeholder="Votre numéro de téléphone" />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[{ required: true, type: "email", message: "Veuillez saisir votre mot de e-mail!" }]}
        >
          <Input type="email" placeholder="Votre e-mail" />
        </Form.Item>

        <Form.Item
          label="Mot de passe"
          name="password"
          rules={[{ required: true, message: "Veuillez saisir votre mot de passe!" }]}
        >
          <Input.Password type="password" placeholder="Votre mot de passe" />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirmez votre mot de passe"
          dependencies={["password"]}
          rules={[
            { required: true, message: "Veuillez confirmer votre mot de passe!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Les deux mots de passe que vous avez saisis ne correspondent pas!"));
              }
            })
          ]}
        >
          <Input type="password" placeholder="Votre mot de passe" />
        </Form.Item>

        <Button
          className="uppercase"
          disabled={submitted}
          loading={submitted}
          color="primary"
          variant="contained"
          style={{ height: 48 }}
          block
          type="submit"
        >
          {submitted ? <Loader /> : "S'inscrire"}
        </Button>

        <div className="text-xs text-center">
          Vous avez déjà un compte ?{" "}
          <a href="/login" className="font-semibold text-primary">
            CONNECTEZ-VOUS
          </a>
        </div>
      </div>
    </Form>
  );
}
export default LoginForm;
