import { Link, navigate } from "@reach/router";
import { Avatar, Dropdown, Menu, Popover } from "antd";
import { useUser } from "app/contexts/user-context";
import { SaveIcon, UserIcon } from "app/icons";
import Button from "../button";
import { StyledHeader } from "./styles";

const menu = (
  <Menu
    className=" min-w-[180px]"
    items={[
      {
        key: "1",
        label: (
          <a rel="noopener noreferrer" href="account">
            Mon compte
          </a>
        ),
        className: "py-3"
      },
      {
        key: "dfd",
        dashed: true,
        type: "divider"
      },
      {
        key: "3",
        label: <Link to="/logout">Déconnexion</Link>,
        className: "py-3"
      }
    ]}
  />
);

const AppBar = (): JSX.Element => {
  const { user } = useUser();

  const handleClick = () => {
    navigate("/subscriptions");
  };

  const content = (
    <div className="flex flex-col gap-5 text-center">
      {user?.credits <= 0 ? (
        <>
          <span className="font-medium">
            Vous n&apos;avez aucun crédit de téléchargement <br /> choisissez parmi nos offres
          </span>
          <Button className="!p-4" variant="contained" onClick={handleClick}>
            Acheter
          </Button>
        </>
      ) : (
        <>Vous avez {user?.credits} crédit(s) de téléchargement restant valable 06 mois</>
      )}
    </div>
  );

  return (
    <StyledHeader className="bg-primary flex h-[64px] justify-between items-center  pr-4">
      <div className="flex items-center h-full gap-4 left ">
        <Link to="/" className="h-full p-3 bg-[#ccd7e8]">
          <img src="/logo.png" alt="" className="h-full" />
        </Link>
        <a href="/" className="hidden text-white md:inline">
          Tableau de bord
        </a>
      </div>

      <div className="flex gap-4 right">
        <Popover placement="bottomRight" content={content} trigger={["click"]}>
          <Button className="!bg-[#F48C06] !p-4" color="secondary" variant="contained">
            <SaveIcon className="w-5" />
            <span className="font-medium">{user?.credits}</span>
          </Button>
        </Popover>

        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
          <Avatar className="profile-btn">
            <UserIcon />
          </Avatar>
        </Dropdown>
      </div>
    </StyledHeader>
  );
};

export default AppBar;
export * from "./editor";
