import { navigate } from "@reach/router";
import Button from "app/components/ui/button";
import { useStore } from "app/contexts/store-context";
import useConfirmDownload from "app/hooks/use-download";
import { ArrowLeftIcon, ArtIcon, EyeIcon, GridIcon, SaveIcon } from "app/icons";
import { previewDrawerAtom, templateDrawerAtom } from "app/store/template";
import { useAtom } from "jotai";
import ResumeTitleInput from "../resume-title-input";
import { StyledHeader } from "./styles";

export const EditorBar = (): JSX.Element => {
  const [, showTemplateDrawer] = useAtom(templateDrawerAtom);
  const [, showPreviewDrawer] = useAtom(previewDrawerAtom);

  const {
    state: { resume },
    dispatch
  } = useStore();

  const { downloadResume } = useConfirmDownload();

  const handleDownload = () => {
    downloadResume(resume);
  };
  const handleOpenTemplateDrawer = () => {
    showTemplateDrawer(true);
  };

  const handleRelooking = () => {
    navigate(`/editor/rsme/${resume.id}/appearance`);
  };

  const handleBack = () => {
    navigate("/");
  };

  const handleTitleChange = (title: string) => {
    dispatch({ type: "resume", payload: { ...resume, title } });
  };

  const handlePreview = () => {
    showPreviewDrawer(true);
  };

  return (
    <StyledHeader className="bg-primary flex h-[64px] justify-between">
      <div className="flex items-center flex-1 gap-4">
        <div className="hidden sm:flex left text-tidny w-[64px] p-3 h-full opacidty-25  items-center justify-center text-white bg-[#ccd7e8] ">
          <img src="/logo.png" alt="" />
        </div>

        <Button color="secondary" onClick={handleBack} className="ml-1" startIcon={<ArrowLeftIcon color="#fff" />}>
          <span className="hidden text-md">Retour</span>
        </Button>
        <ResumeTitleInput resume={resume} onChange={handleTitleChange} />
      </div>

      <div className="flex items-center pr-2 space-x-4">
        {resume?.template?.customizable && (
          <Button color="secondary" onClick={handleRelooking} className="!hidden sm:!flex  border-9 shadow-md">
            <ArtIcon color="#fff" />
            <span>Personnaliser</span>
          </Button>
        )}

        <Button color="secondary" onClick={handleOpenTemplateDrawer} className=" !hidden sm:!flex  border-9 shadow-md">
          <GridIcon color="#fff" />
          <span>Changer de modèle</span>
        </Button>

        <Button
          onClick={handlePreview}
          className="sm:!hidden shadow-md"
          startIcon={<EyeIcon color="#fff" />}
          variant="contained"
          color="secondary"
        />

        <Button
          onClick={handleDownload}
          className="shadow-md"
          startIcon={<SaveIcon color="#fff" />}
          variant="contained"
          color="secondary"
        >
          <span className="hidden sm:inline"> Télécharger</span>
        </Button>
      </div>
    </StyledHeader>
  );
};

export default EditorBar;
