import { RouteComponentProps } from "@reach/router";
import RelookingLayout from "app/components/ui/layout/relooking";
import { StyledProgressItem } from "app/components/ui/progress/styles";
import { useStore } from "app/contexts/store-context";
import useTemplate from "app/hooks/useTemplate";
import React, { ChangeEvent, useMemo, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import reactCSS from "reactcss";
import { Collapse, Select } from "antd";
import { SketchPicker } from "react-color";
import { formatDate } from "app/utils/date";
import clsx from "clsx";

import Page from "../preview/page";
import { StyledWrapper } from "./styles";

import ColorsList from "./colors-list";

const { Panel } = Collapse;

const FONT_FAMILIES = ["Arial", "Calibri", "Helvetica", "Times New Roman"];
const FONT_SIZE = ["XSS", "XS", "S", "M", "L", "XL", "XLL"];

const LINE_HEIGHT = [
  {
    value: "M",
    label: 1
  },
  {
    value: "L",
    label: "1,15"
  },
  {
    value: "XL",
    label: "1,15"
  },
  {
    value: "XLL",
    label: "1,5"
  },
  {
    value: "XLLL",
    label: "2"
  }
];

const DATE_FORMATS = [
  {
    format: "MM/yyyy",
    value: "10/10/1997",
    label: "mm/aaaa"
  },
  {
    format: "MM-yyyy",
    value: "10/10/1997",
    label: "mm-aaaa"
  },
  {
    format: "MM.yyyy",
    value: "10/10/1997",
    label: "mm.aaaa"
  },
  {
    format: "yyyy/MM",
    value: "10/10/1997",
    label: "aaaa/mm"
  }
];

const Customization = (props: RouteComponentProps) => {
  const {
    state: { resume, theme },
    dispatch
  } = useStore();
  const Template = useTemplate(resume.template);
  const [currentColor, setCurrentColor] = useState(null);

  const [state, setState] = useState({
    displayColorPicker: false,
    color: {
      r: "241",
      g: "112",
      b: "19",
      a: "1"
    }
  });

  const settings = useMemo(() => resume?.settings || resume?.template?.settings, [resume]);

  const handleColorChangeBgColor = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "theme",
      payload: {
        ...theme,
        colors: { ...theme.colors, bg: { ...theme.colors.bg, [event.target.name]: event.target.value } }
      }
    });
  };

  const handleClick = (data: any) => {
    // setState({ ...state, displayColorPicker: !state.displayColorPicker });
    setCurrentColor(data);
    // console.log(data);
  };

  const handleClose = () => {
    // setState({ ...state, displayColorPicker: false });
    setCurrentColor(null);
  };

  const handleColorChange = (color) => {
    // setState({ ...state, color: color.rgb });
    const { colors } = settings;
    // console.log(colors);
    const data = [...colors[currentColor.type]];
    data[currentColor.index] = color.hex;
    dispatch({
      type: "resume",
      payload: {
        ...resume,
        settings: {
          ...settings,
          colors: {
            ...colors,
            [currentColor.type]: data
          }
        }
      }
    });

    // colors[currentColor.type][currentColor.index] = color.hex;

    // console.log(colors);
  };

  const handleFontFamily = (value) => {
    dispatch({
      type: "resume",
      payload: {
        ...resume,
        settings: {
          ...settings,
          fontFamily: value
        }
      }
    });
  };

  const handleFontSize = (value) => {
    dispatch({
      type: "resume",
      payload: {
        ...resume,
        settings: {
          ...settings,
          fontSize: value
        }
      }
    });
  };

  const handleDateFormat = (format: string) => {
    dispatch({
      type: "resume",
      payload: {
        ...resume,
        settings: {
          ...settings,
          dateFormat: format
        }
      }
    });
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${state.color.r}, ${state.color.g}, ${state.color.b}, ${state.color.a})`
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer"
      },
      popover: {
        position: "absolute",
        zIndex: "2",
        top: 50,
        right: "240px"
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px"
      }
    }
  });

  return (
    <RelookingLayout>
      <StyledWrapper className="p-2 flex flex-col lg:grid grid-cols-[300px_calc(100%_-_540px)_240px]">
        <div className="space-y-4 sm:hidden">
          <StyledProgressItem defaultActiveKey="dfsd">
            <Panel key="dfsd" header="Couleurs">
              <section className="py-1 border-t-2">
                <span className="text-xs font-semibold">Couleurs des blocs</span>

                <div className="py-4">
                  <ColorsList type="bg" colors={settings?.colors?.bg || []} onClick={handleClick} />
                </div>
              </section>

              <section className="py-1 border-t-2">
                <span className="text-xs font-semibold">Couleurs des textes</span>

                <div className="py-4">
                  <ColorsList type="text" colors={settings?.colors?.text || []} onClick={handleClick} />
                </div>
              </section>
            </Panel>
          </StyledProgressItem>

          <StyledProgressItem defaultActiveKey="dfsd">
            <Panel key="dfsd" header="Police de caractère">
              <div>
                <Select className="w-full" value={settings?.fontFamily} onChange={handleFontFamily}>
                  {FONT_FAMILIES.map((font) => (
                    <Select.Option key={font}>{font}</Select.Option>
                  ))}
                </Select>
              </div>
            </Panel>
          </StyledProgressItem>

          <StyledProgressItem defaultActiveKey="dfsd">
            <Panel key="dfsd" header="Taille de police">
              <div>
                <Select className="w-full" value={settings?.fontSize} onChange={handleFontSize}>
                  {FONT_SIZE.map((size) => (
                    <Select.Option key={size}>{size}</Select.Option>
                  ))}
                </Select>
              </div>
            </Panel>
          </StyledProgressItem>

          <StyledProgressItem defaultActiveKey="dfsd">
            <Panel key="dfsd" header="Format des dates">
              Comment voulez-vous afficher les dates sur votre CV ?
              <div className="space-y-2">
                {DATE_FORMATS.map(({ label, format, value }) => (
                  <div
                    aria-hidden
                    onClick={() => handleDateFormat(format)}
                    className={clsx("border-2 p-2 rounded-md flex flex-col gap-1 cursor-pointer ", {
                      "border-primary": format === settings?.dateFormat
                    })}
                  >
                    <span className="text-xs font-semibold">{label}</span>
                    <span className="text-salte-10 text-slate-600 ">ex: {formatDate(value, format)}</span>
                  </div>
                ))}
              </div>
            </Panel>
          </StyledProgressItem>
        </div>
        <div className="hidden p-2 bg-white border-2 rounded-lg shadow-md lg:block">
          <StyledProgressItem>
            <Panel key="dfsd" header="Format des dates">
              Comment voulez-vous afficher les dates sur votre CV ?
              <div className="space-y-2">
                {DATE_FORMATS.map(({ label, format, value }) => (
                  <div
                    aria-hidden
                    onClick={() => handleDateFormat(format)}
                    className={clsx("border-2 p-2 rounded-md flex flex-col gap-1 cursor-pointer ", {
                      "border-primary": format === settings?.dateFormat
                    })}
                  >
                    <span className="text-xs font-semibold">{label}</span>
                    <span className="text-salte-10 text-slate-600 ">ex: {formatDate(value, format)}</span>
                  </div>
                ))}
              </div>
            </Panel>
          </StyledProgressItem>
        </div>
        <div id="preview-wrapper" className="justify-center hidden content lg:flex">
          <Page>
            <React.Suspense fallback="">
              {Template &&
                React.cloneElement(<Template />, {
                  resume,

                  sections: resume.sections?.reduce((prev, curr) => ({ ...prev, [curr.name]: curr }), {}),

                  settings: resume?.settings || resume?.template?.settings
                })}
            </React.Suspense>
          </Page>
        </div>
        <div className="hidden p-3 side-bar lg:block">
          <div className="space-y-4">
            <section className="py-1 border-t-2">
              <span className="text-xs font-semibold">Couleurs des blocs</span>

              <div className="py-4">
                <ColorsList type="bg" colors={settings?.colors?.bg || []} onClick={handleClick} />
              </div>
            </section>

            <section className="py-1 border-t-2">
              <span className="text-xs font-semibold">Couleurs des textes</span>

              <div className="py-4">
                <ColorsList type="text" colors={settings?.colors?.text || []} onClick={handleClick} />
              </div>
            </section>

            <section className="py-1 border-t-2">
              <span className="text-xs font-semibold">Police de caractère</span>
              <div>
                <Select className="w-full" value={settings?.fontFamily} onChange={handleFontFamily}>
                  {FONT_FAMILIES.map((font) => (
                    <Select.Option key={font}>{font}</Select.Option>
                  ))}
                </Select>
              </div>
            </section>

            <section className="py-1 border-t-2">
              <span className="text-xs font-semibold">Taille de police</span>
              <div>
                <Select className="w-full" value={settings?.fontSize} onChange={handleFontSize}>
                  {FONT_SIZE.map((size) => (
                    <Select.Option key={size}>{size}</Select.Option>
                  ))}
                </Select>
              </div>
            </section>
          </div>
        </div>
        {currentColor ? (
          <div style={styles.popover}>
            <div aria-hidden style={styles.cover} onClick={handleClose} />
            <SketchPicker color={currentColor.color} onChange={handleColorChange} />
          </div>
        ) : null}
      </StyledWrapper>
    </RelookingLayout>
  );
};

export default Customization;
