import { Modal as AntModal } from "antd";
import styled from "styled-components";

export const StyledModal = styled(AntModal)`
  .ant-modal-body {
    max-height: 55vh;
    overflow: hidden;
    overflow-y: scroll;
  }

  .ant-modal-close-x {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 70px;

    svg {
      background-color: #edf1f7;
      border-radius: 3px;
      color: #19212c;
      height: 22px;
      padding: 4px;
      width: 22px;
    }
  }

  .ant-modal-header {
    border-bottom: 0;
    padding: 24px;
  }

  .ant-modal-title {
    font-size: 20px;
    font-weight: bold;
    color: var(--neutral-900);
  }

  .ant-modal-footer {
    border-top: 0;
    padding: 24px;
  }
`;

export const StyledFooterContent = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  .extra {
    text-align: left;
  }

  .alignButtons {
    display: flex;
    gap: 6px;
  }

  button {
    border-radius: 4px;
    min-width: 105px;
    &.cancel {
      margin-right: 8px;
    }
  }
`;
